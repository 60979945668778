import React, { useEffect } from "react";
import { createState, State, useState } from "@hookstate/core";
import "./App.css";
import axios from "axios";
import { ArchiveEntry, IArchiveEntryProps } from "./components/archive-entry";
import { InputGroup } from "@blueprintjs/core/lib/esm/components";

const defaultStore = {
	archive: [
		{
			key: "0",
			image:
				"https://assets.mubicdn.net/images/notebook/post_images_danny/multiple%20images/MPOTW/houseposter_500.jpg",
			title: "Hausu (1977)",
			reviews: [
				{
					reviewerName: "Bowes",
					reviewRating: 0,
					reviewText: "As a movie utter shiet, as meme potential. *chefs kiss*",
				},
				{
					reviewerName: "Christiian",
					reviewRating: 8,
					reviewText: "Pure Art",
				},
				{
					reviewerName: "Jordan",
					reviewRating: 5,
					reviewText: "I like how bad it was, but I hated it as a movie. Dream camera wants me to be killed by cat.",
				},
				{
					reviewerName: "Ian",
					reviewRating: 1,
					reviewText: "Lots of flashing, 10/10 would kill epileptic kids again.",
				}
			],
		},
	],
};
export const archiveStore = createState(defaultStore);



const HeaderBar = () => {
	const isAddingArchive = useState(false);
	const archiveData = useState(archiveStore);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		isAddingArchive.set( false );
		const data = new FormData(e.currentTarget);

		const newArchive = {
			key: String(Math.random()),
			title: String(data.get("archiveName")),
			image: String( data.get( "archiveImage" ) ),
			reviews: [
				{
					reviewerName: "Bowes",
					reviewRating: Number(data.get("bowesNumber")),
					reviewText: String(data.get("bowesReview")).replace(/"/g, "'").replace(/[\/\(\)\']/g, "&apos;"),
				},
				{
					reviewerName: "Christiian",
					reviewRating: Number(data.get("christiianNumber")),
					reviewText: String(data.get("christiianReview")).replace(/"/g, "'").replace(/[\/\(\)\']/g, "&apos;"),
				},
				{
					reviewerName: "Jordan",
					reviewRating: Number(data.get("jordanNumber")),
					reviewText: String(data.get("jordanReview")).replace(/"/g, "'").replace(/[\/\(\)\']/g, "&apos;"),
				},
				{
					reviewerName: "Ian",
					reviewRating: Number(data.get("ianNumber")),
					reviewText: String(data.get("ianReview")).replace(/"/g, "'").replace(/[\/\(\)\']/g, "&apos;"),
				}
			]
		};

		archiveData.set((prevState) => ({
			archive: [...prevState.archive, newArchive],
		}));
		postArchive(newArchive);
	};
	return (
		<>
			<div className="headerBar">
				<div className="logoContainer">The Archive</div>
				<div className="addArchive" onClick={() => isAddingArchive.set(true)}>
					+
				</div>
			</div>

			{isAddingArchive.value && (
				<div className="fullScreenScrim">
					<div className="addArchiveContainer">
						<div className="formTitle">Add New Archive</div>
						<form className="addArchiveForm" onSubmit={handleSubmit}>
							<label>Archive name:</label>
							<br />
							<input type="text" id="archiveName" name="archiveName" required />
							<label>Archive image (url):</label>
							<br />
							<input
								type="text"
								id="archiveImage"
								name="archiveImage"
								required
							/>
							<label>Bowes Score (out of 5):</label>
							<br />
							<input
								type="number"
								id="bowesNumber"
								name="bowesNumber"
								required
							/>
							<label>Bowes Review:</label>
							<br />
							<input type="text" id="bowesReview" name="bowesReview" required />
							<label>Christiian Score (out of 5):</label>
							<br />
							<input
								type="number"
								id="christiianNumber"
								name="christiianNumber"
								required
							/>
							<label>Christiian Review:</label>
							<br />
							<input
								type="text"
								id="christiianReview"
								name="christiianReview"
								required
							/>
							<label>Jordan Score (out of 5):</label>
							<br />
							<input
								type="number"
								id="jordanNumber"
								name="jordanNumber"
								required
							/>
							<label>Jordan Review:</label>
							<br />
							<input
								type="text"
								id="jordanReview"
								name="jordanReview"
								required
							/>
							<label>Ian Score (out of 5):</label>
							<br />
							<input type="number" id="ianNumber" name="ianNumber" required />
							<label>Ian Review:</label>
							<br />
							<input type="text" id="ianReview" name="ianReview" required />
							<label>Password:</label>
							<br />
							<input
								type="password"
								id="submitPassword"
								name="submitPassword"
								required
							/>
							<div className="formButtons">
								<div
									className="cancelButton"
									onClick={() => isAddingArchive.set(false)}
								>
									Cancel
								</div>
								<input
									type="submit"
									id="archiveSubmit"
									name="archiveSubmit"
									value="Submit"
								/>
							</div>
						</form>
					</div>
				</div>
			)}
		</>
	);
}

async function postArchive(archiveData: any) {
	console.log("Archive Post: " + JSON.stringify(archiveData));
	await axios
		.post("/api/link.php", JSON.stringify(archiveData))
		.then((response) => {
			console.log("Successfully posted: " + response.data);
		})
		.catch((response) => {
			console.log("ERROR: " + response);
		});
}

function App() {
	const isLoading = useState(false);
	const archiveData = useState( archiveStore );
	useEffect(() => {
		isLoading.set(true);
		axios
			.get("./api/link.php")
			.then((response) => response.data)
			.then((data) => {
				//console.log(typeof data);
				let dataCollection: any[] = [];
				data.forEach( ( element: string ) => {
					dataCollection.push(JSON.parse(element));
				});
				if (data === "") {
					archiveData.set(defaultStore);
				} else {
					archiveData.archive.set(dataCollection);
				}
				isLoading.set(false);
			});
	}, []);

	return (
		<div className="App">
			<HeaderBar />
			{!isLoading.value ? (
				(console.log(archiveData),
				(
					<div className="archiveList">
						{archiveData.archive.value.map((archive: IArchiveEntryProps) => (
							<ArchiveEntry
								key={archive.key}
								title={archive.title}
								image={archive.image}
								reviews={archive.reviews}
							/>
						))}
					</div>
				))
			) : (
				<div>Loading...</div>
			)}
		</div>
	);
}

export default App;

export interface IArchiveStore {
	archive: IArchiveEntryProps[];
}
