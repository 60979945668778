import { useState } from "@hookstate/core";

export interface IReview {
	reviewerName: string;
	reviewRating: number;
	reviewText: string;
}

const Review = ( props: IReview ) => {
    const reviewColor = props.reviewRating > 5 ? 5 : props.reviewRating;
	return (
		<>
			<div className="reviewName">{props.reviewerName}</div>
			<div className="reviewEntry">
				<div className={`reviewNumber rank${reviewColor}`}>
					{props.reviewRating}/5
				</div>
				<div className={`reviewText `}>{props.reviewText}</div>
			</div>
		</>
	);
};

function round(value:number, precision:number) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export interface IArchiveEntryProps {
	key: string;
	image: string;
	title: string;
	reviews: IReview[];
}

export const ArchiveEntry = (props: IArchiveEntryProps) => {
    const isFullScreen = useState( false );
	let entryOverallScore = 0;
	
	for ( let i = 0; i < props.reviews.length; i++ ) {
        entryOverallScore += props.reviews[i].reviewRating;
    }
    entryOverallScore = round(entryOverallScore / props.reviews.length,1);
	return (
		<>
			<div
				key={props.key}
				className="archiveEntry"
				onClick={() => isFullScreen.set(true)}
            >
                <div className="entryAverageScoreContainer"><span className="entryAverageScore">{entryOverallScore}</span></div>
				<img
					alt={props.title}
					className="archiveEntryImage"
					src={props.image}
				/>
			</div>
			{isFullScreen.value && 
				<div
					className="fullScreenScrim"
					onClick={() => isFullScreen.set(false)}
				>
					<div className="archiveEntryFull">
						<div className="archiveEntryTitle">{props.title}</div>
						<img
							alt={props.title}
							className="archiveEntryImageLarge"
							src={props.image}
						/>
						<div className="archiveEntryReviews">
							{props.reviews.map((review: IReview) => (
								<Review
									reviewerName={review.reviewerName}
									reviewRating={review.reviewRating}
									reviewText={review.reviewText}
								/>
							))}
						</div>
					</div>
				</div>
            }
		</>
	);
};
